export default {
    firstNameErrors() {
        if (!this.$v.$anyError) return null;
        if (!this.$v.createModal.user.FirstName.required) return "required_field";
        return null;
    },

    lastNameErrors() {
        if (!this.$v.$anyError) return null;
        if (!this.$v.createModal.user.LastName.required) return "required_field";
        return null;
    },

    emailErrors() {
        if (!this.$v.$anyError) return null;
        if (!this.$v.createModal.user.Email.required) return "required_field";
        if (!this.$v.createModal.user.Email.email) return "no_valid";
        return null;
    },

    phoneErrors() {
        if (!this.$v.$anyError) return null;
        if (!this.$v.createModal.user.Phone.required) return "required_field";
        if (!this.$v.createModal.user.Phone.isMobilePhone) return "no_valid";
        return null;
    },

    passwordErrors() {
        if (!this.$v.$anyError) return null;
        if (!this.$v.createModal.user.Password.required) return "required_field";
        if (!this.$v.createModal.user.Password.minLength) return "password_must_have_a_least_6_chars";
        if (!this.$v.createModal.user.Password.maxLength) return "password_must_have_a_maxim_50_chars";
        return null;
    },
    confirmPasswordErrors() {
        if (!this.$v.$anyError) return null;
        if (!this.$v.createModal.user.PasswordConfirm.required) return "required_field";
        if (!this.$v.createModal.user.PasswordConfirm.sameAsPassword) return "passwords_must_match";
        return null;
    },
};