import {required, minLength, maxLength, sameAs, email} from 'vuelidate/lib/validators'

const phoneRegexp = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/

const isMobilePhone = (value) => {
    return phoneRegexp.test(value);
};

export default {
    createModal: {
        user: {
            FirstName: {
                required,
            },
            LastName: {
                required,
            },
            Email: {
                required,
                email
            },
            Phone: {
                required,
                isMobilePhone
            },
            Password: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(50)
            },
            PasswordConfirm: {
                sameAsPassword: sameAs('Password')
            },
        }
    }
}
