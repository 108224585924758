<template>
  <div class="item" v-if="Level">
    <div class="order">
      <i class="ez-icon-drag"></i>
      <span>{{ Level.Order }}</span>
    </div>

    <TextBox v-model="Level.Name" :placeholder="$t('level_name')" @keyup="update"/>
    <TextBox type="num" v-model="Level.Bonus" :placeholder="$t('bonus')" @keyup="update"/>
    <TextBox type="num" v-model="Level.DealsUnit" :placeholder="$t('deals_count')" @keyup="update"/>
    <TextBox type="num" v-model="Level.FlatDeal" :placeholder="$t('flat_deal')" @keyup="update"/>
    <ColorPicker v-model="Level.Color"
                 @change="update"
                 row-length="5"
                 positionX="left"
    />
    <div class="remove" @click="Delete">
      <i class="ez-icon-delete"></i>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/ColorPicker/index";

export default {
  name: "index",
  components: {ColorPicker},
  props: {
    value: Object,
    onDelete: Function,
    onUpdate: Function,
    index: Number
  },

  data() {
    return {
      Level: null
    }
  },

  mounted() {
    this.Level = this.value;
  },

  methods: {
    Delete() {
      if (this.onDelete) this.onDelete(this.index);
    },

    update() {
      this.$emit('edit', {index: this.index, Level: this.Level});
    }
  }
}
</script>

<style scoped lang="scss">
.item {
  list-style: none;
  display: grid;
  gap: 10px;
  grid-template-columns: 50px 150px repeat(3, minmax(80px, 1fr)) 35px 35px;
  grid-template-columns: subgrid;
  border: 1px solid color(border);
  background: color(bg-gray-light);
  padding: 10px;

  .order {
    background: color(bg-gray-light);
    cursor: move;
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    border-radius: 3px;

    span {
      color: color(bg-black3);
    }
  }

  .remove {
    background: color(bg-red-light);
    border-radius: 3px;
    border: 1px solid color(bg-red);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: color(bg-red);
    }

    &:hover {
      background: color(bg-red);

      i {
        color: color(bg-white);
      }
    }
  }


}
</style>