import Utils from '@/helpers/utils';
import HttpClient from "@/helpers/http.client";

import Validations from './validation';
import Errors from './Errors';
import UsersTrackerService from '@/services/users.tracker.service';


const http = new HttpClient();

const
    service = new UsersTrackerService(http);

export default {
    name: 'tracker',
    components: {},
    props: [],
    data() {
        return {
            users: [],
            createModal: {
                show: false,
                user: {
                    id: "",
                    Username: "",
                    FirstName: "",
                    LastName: "",
                    Phone: "",
                    Email: "",
                    Password: "",
                    PasswordConfirm: "",
                },
            },
            deleteModal: {
                Shown: false,
                UserId: '',
            },

            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            searchTerm: '',
            validation: '',
            errors: {},
        }
    },
    computed: {
        ...Errors
    },
    async mounted() {
        await this.list();
    },
    validations: Validations,
    methods: {
        async save() {
            await this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.createModal.user.Phone = this.$normalizeNumber(this.createModal.user.Phone);
            this.createModal.user.Username = this.createModal.user.Phone;

            const res = await service.create(this.createModal.user);
            if (res && !res.error) {
                await this.list();
                this.cancelModal();
                this.resetFormFields();
            } else {
                this.validation = res.message;
            }
        },

        async remove(id) {
            const res = await service.delete({id});
            if (res && !res.error) {
                this.users.length > 1 ? await this.list(this.currentPage) : await this.list();
                this.deleteModal = false
            } else {
                //TODO: Handle Error
            }
        },

        async list(page) {
            this.currentPage = page || 1;

            const skip = this.perPage * (this.currentPage - 1);
            const res = await service.list(this.perPage, skip, this.searchTerm);
            if (res && !res.error) {
                this.users = res.data.users;
                this.totalRows = res.data.count;
            }
        },

        async setPerPage(num) {
            this.perPage = +(num);
            await this.list();
        },

        resetFormFields() {
            this.createModal.user = {
                Username: "",
                FirstName: "",
                LastName: "",
                Phone: "",
                Email: "",
                Password: "",
                PasswordConfirm: ""
            }
            this.validation = "";
        },

        openCreateModal() {
            this.createModal.show = true;
        },

        cancelModal() {
            this.createModal.show = false;
            this.resetFormFields();
            this.$nextTick(() => {
                this.$v.$reset()
            })
        },

        highlight(text) {
            return Utils.HighlightText(text, this.searchTerm);
        },
    },
}


