import {mapActions} from 'vuex';
import CompanyService from '@/services/company.service';
import LevelItem from './LevelItem/index.vue';

const service = new CompanyService();

export default {
    name: 'score-levels',
    components: {LevelItem},
    props: [],
    data() {
        return {
            Level: {
                Type: "",
                Name: '',
                Bonus: 0,
                DealsUnit: 0,
                FlatDeal: 0,
                Color: '',
                Order: null
            },
            TypeOptions: [
                {
                    value: "SELLER",
                    text: "Seller"
                },
                {
                    value: "BDC",
                    text: "BDC"
                }
            ],
            newLevelAdded: false,
            LevelIndex: null,
            CreateModal: false,
            EditModal: false,
            RemoveModal: false
        }
    },
    computed: {
        CompanySettings: {
            get() {
                return this.$store.getters.companySettings;
            },

            set(companySettings) {
                this.setCompanySettings(companySettings);
            }
        },

        ScoreLevels() {
            return this.CompanySettings.ScoreLevels;
        }
    },

    async mounted() {
    },

    async beforeMount() {
        await this.fetch();
    },

    methods: {

        async fetch() {
            const res = await service.getSettings();
            if (res && !res.error) {
                this.setCompanySettings(res.data);
            }
        },

        ...mapActions([
            'setCompanySettings'
        ]),

        async addLevel(type) {

            const amount = this.CompanySettings.ScoreLevels.filter(l => l.Type === type).length;
            this.Level.Order = amount + 1;
            this.Level.Type = type;
            this.CompanySettings.ScoreLevels.push(JSON.parse(JSON.stringify(this.Level)));
            this.newLevelAdded = true;
            await service.updateScoreLevels(this.ScoreLevels);
        },

        saveOnly({index, Level}) {
            this.CompanySettings.ScoreLevels[index] = Level;
            service.updateScoreLevels(this.ScoreLevels).then(null).catch(null);
        },

        async saveChanges() {
            const res = await service.updateScoreLevels(this.ScoreLevels);
            if (res && !res.error) {
                this.Level = {
                    Type: "",
                    Name: '',
                    Bonus: 0,
                    DealsUnit: 0,
                    FlatDeal: 0,
                    Color: '',
                    Order: null
                };

                this.$notify({
                    type: 'success',
                    message: this.$t('new_level_added')
                });

                this.newLevelAdded = false;

                const res = await service.getSettings();
                if (res && !res.error) {
                    this.setCompanySettings(res.data);
                }
            }
            //TODO: Notify error;

        },

        async editLevel() {
            const level = JSON.parse(JSON.stringify(this.Level));
            this.CompanySettings.ScoreLevels.splice(this.LevelIndex, 1, level);
            const res = await service.updateScoreLevels(this.ScoreLevels);
            if (res && !res.error)
                this.onCloseModal();
        },

        async removeLevel() {
            this.CompanySettings.ScoreLevels.splice(this.LevelIndex, 1);

            this.reAdjustOrder();

            const res = await service.updateScoreLevels(this.ScoreLevels);
            if (res && !res.error)
                this.RemoveModal = false;
        },

        reAdjustOrder() {
          const count = {
              "SELLER": 0,
              "BDC": 0
          };

          for ( const level of this.ScoreLevels) {
              count[level.Type]++;
              level.Order = count[level.Type];
          }
        },

        onCreateModal() {
            this.CreateModal = true;
        },

        onCloseModal() {
            this.Level = {
                Type: "",
                Name: '',
                Bonus: 0,
                DealsUnit: 0,
                FlatDeal: 0,
                Color: '',
                Order: null
            };

            this.CreateModal = false;
            this.RemoveModal = false;
        },

        onEditModal(index) {
            this.Level = JSON.parse(JSON.stringify(this.ScoreLevels[index]))
            this.LevelIndex = index;
            this.EditModal = true;
        },

        onRemoveModal(index) {
            this.LevelIndex = index;
            this.RemoveModal = true;
        }
    }
}


