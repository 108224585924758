import IService from './iservice';
import config from "@/config";

const server_base = config[config.stage].ws_server;

class UsersTrackerService extends IService{
    constructor(http){
        super(http);
    }

    async create(user){
        try{
            let response = await this.http.post(`${server_base}/api/trackers`, user);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async list(){
        try{
            let response = await this.http.get(`${server_base}/api/trackers`);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async delete(user){
        try{
            let response = await this.http.delete(`${server_base}/api/trackers`, user);
            return response.data;
        }catch (e) {
            return null;
        }
    }
}

export default UsersTrackerService;
